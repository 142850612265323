@import url("https://fonts.googleapis.com/css2?family=Orelega+One&display=swap");

@font-face {
  font-family: "museo_slab700";
  src: url("fonts/museo_slab_4-webfont.woff2") format("woff2"),
    url("fonts/museo_slab_4-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #f5f6f7;
  font-family: "Orelega One", cursive;
}

label {
  color: #56535b;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
  /* 142.857% */
}
.pointer {
  cursor: pointer;
}

.h-100vh {
  min-height: 100vh;
}

.custom-css {
  flex-direction: unset !important;
}

.pagination .selected {
  color: #7c0279;
}

.pagination {
  align-items: center;
}

.logo {
  max-width: 150px;
}

.login_area {
  padding-left: 100px;
}

.login_area p {
  color: #56535b;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Orelega One", cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  /* 125% */
  text-transform: capitalize;
}

input,
textarea:focus,
input,
textarea:active,
input,
textarea:hover {
  outline: none;
  background-color: #ffffff;
  background: #ffffff;
}

input,
textarea {
  border-radius: 8px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 0px 44px 92px 0px rgba(159, 159, 159, 0.25);
  display: flex;
  padding: 10px 14px 10px 15px;
  resize: none;
  font-weight: 400;
  gap: 197px;
  width: 100%;
  font-family: "museo_slab700";
  font-size: 14px;
}
/* override padding for Bootstrap radios */
.form-check-input[type="radio"] {
  padding: 0 !important;
}

.editIcon {
  padding: 15px;
  background-color: #dee2e6;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
button:focus,
button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border: none;
}

.site_btn button {
  background: linear-gradient(to bottom, #7c0279 0%, #cc0099 100%);
  color: #ffffff;
  border: 1px solid #7c0279;
  width: 100%;
  height: 50px;
  border-radius: 15px;
}

.search_icon {
  fill: rgba(79, 84, 84, 0.5);
}

.form-group.search_input input {
  padding-left: 45px;
}

span.search_icons {
  position: absolute;
  top: 8px;
  left: 15px;
}

.center_img img {
  width: 75%;
}

.forget a,
.forget button {
  color: #56535b;
  text-decoration: none;
  font-family: "museo_slab700";
  font-size: 14px;
}

.inner_logo img {
  max-width: 130px;
}

.header {
  padding: 15px;
  border-bottom: 1px solid rgba(86, 83, 91, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sideBar {
  border-right: 1px solid rgba(86, 83, 91, 0.2);
}

button {
  border: none;
  outline: none;
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile img {
  max-width: 35px;
}

.dropdown-toggle {
  color: #56535b;
  font-family: "museo_slab700";
  font-size: 14px;
  background: transparent;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu-end {
  top: 15px !important;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  border: none;
}

.norification {
  padding: 20px 15px;
}

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 0 20px;
  overflow-y: auto;

  scrollbar-color: #aab7cf transparent;
}

main {
  transition: all 0.3s;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.menu_title {
  color: rgba(86, 83, 91, 0.8);
  font-size: 12px;
  font-family: "museo_slab700";
  margin-bottom: 24px;
  margin-top: 24px;
}

.sidenav {
  padding: 0 15px;
  list-style: none;
}

.sidenav .nav-item {
  border-radius: 3px;
  color: #56535b;
  font-family: "museo_slab700";
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  transition: 0.3s;
}

.sidenav .nav-item.active {
  color: #7c0279;
  box-shadow: -2px 0px 0px 0px #7c0279;
  background: rgba(24, 87, 154, 0.1);
  padding: 15px;
}

.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.filter-btn {
  background-color: #7c0279;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
}

#dropdownMenuButton {
  width: 100%;
  text-align: left;
  border: 0.8px solid #7c0279;
  background-color: #fff;
  box-shadow: 0px 44px 92px 0px rgba(159, 159, 159, 0.25);
  padding: 10px 14px 10px 15px;
  margin-top: 23px;
  color: black;
}

#dropdownMenuButton:hover {
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}

.shop-banner {
  max-height: 300px;
}

.card-image {
  max-height: 250px;
  max-width: 90%;
}

.filter-dropdown li {
  width: 100%;
}

.filter-dropdown li button {
  width: 100% !important;
  padding: 9px;
  background-color: transparent;
  margin-bottom: 0px;
}

.pagination {
  display: flex;
  list-style-type: none;
  margin: auto;
  gap: 25px !important;
}
.gapZero {
  gap: 0px !important;
}
/* .sidenav .nav-item:hover {
  color: #7c0279;
  box-shadow: -2px 0px 0px 0px #7c0279;
  background: rgba(0, 146, 153,0.10);
  padding: 15px;

} */

.delete-btn {
  background-color: white !important;
  color: #ff4b26 !important;
  font-size: 12px;
  padding: 0px !important;
  position: absolute;
  margin-top: 10px;
  height: 30px;
  margin-left: 10px;
  border-radius: 5px;
  border-radius: 50% !important;
  width: 30px;
}

.sidenav .nav-item a {
  border-radius: 3px;
  color: #56535b;
  font-family: "museo_slab700";
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  padding: 15px 0;
  margin: 15px 0;
  transition: 0.3s;
}

.sidenav .nav-item a.active {
  color: #7c0279;
  box-shadow: -2px 0px 0px 0px #7c0279;
  background: #cbc0d3;
  padding: 15px;
}

.meals {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.meals .chip {
  background-color: #d9d9d9;
  padding: 4px 13px;
  border-radius: 20px;
  display: flex;
  font-weight: 300;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  line-height: normal;
}
.sidenav .nav-item a:hover {
  color: #7c0279;
  box-shadow: -2px 0px 0px 0px #7c0279;
  background: #cbc0d3;
  padding: 15px;
}

.sidenav .nav-item span {
  padding-right: 10px;
}

.main {
  margin-left: 300px;
}

.edit_btn {
  color: #7c0279;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: "museo_slab700";
  border-radius: 4px;
  background: rgba(24, 87, 154, 0.1);
  padding: 10px;
  gap: 5px;
}

.sidetoggle .main {
  margin-left: 0;
}

.sidetoggle .sidebar {
  margin-left: -300px;
}

.toggle_logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.color-green {
  color: green !important;
}

.color-red {
  color: red !important;
}

.setBorder {
  position: relative;
}

.setBorder::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 0px;
  background: rgb(0 0 0 / 15%);
  top: 0;
}

.setBorder::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  right: 0px;
  background: rgb(0 0 0 / 15%);
  top: 0;
}

.all_comp {
  padding: 20px 30px;
  padding-top: 75px;
}

.pagetitle {
  color: #56535b;
  font-family: "Orelega One", cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 20px;
}

.pagetitle button {
  color: #56535b;
  font-family: "Orelega One", cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 20px;
  background-color: transparent;
  gap: 10px;
}

.card {
  border-radius: 16px;
  background: #e3f5ff;
  border: 0;
}

.card-title {
  color: #56535b;
  font-family: "Orelega One", cursive;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.card .numbers {
  font-size: 24px;
  color: #56535b;
  font-family: "museo_slab700";
  font-style: normal;
  line-height: 36px;
}

.card .graph {
  font-size: 12px;
  color: #56535b;
  font-family: "museo_slab700";
  font-style: normal;
  line-height: 20px;
}

.card-body {
  padding: 20px 30px;
}

.notify_title {
  color: #56535b;
  font-family: "Orelega One", cursive;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.notification_list {
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

.n_item {
  color: #56535b;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  padding-bottom: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.n_time {
  color: #56535b;
  font-family: "museo_slab700";
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.norification {
  padding: 20px 30px;
}

.notification_list li {
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  padding: 10px 0 20px 0;
}

.table th {
  color: #584278;
  font-family: "Orelega One", cursive;
  font-size: 14px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 400;
}

.table thead {
  border-radius: 0px 0px 16px 16px;
  --bs-table-bg: rgba(24, 87, 154, 0.1);
}

.table > :not(caption) > * > * {
  padding: 20px 15px;
}

.table_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table tr td {
  padding: 15px;
}

.table_profile img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.table {
  border-radius: 15px;
  overflow: hidden;
}

.tname,
td {
  color: #56535b !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  font-family: "museo_slab700";
  vertical-align: text-top;
}

.actions .btn {
  margin: 0 2px;
  padding: 0;
}

.actions .view {
  color: #56535b;
}

.actions .block {
  color: #0f993e;
}

.actions .delete {
  color: #ff4b26;
}

.actions button {
  background-color: transparent;
}

.outer_div {
  border-radius: 16px;
  border: 1px solid rgba(86, 83, 91, 0.2);
  background: #fff;
  padding: 20px;
}

.profile_image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.profile_image input {
  height: 0px;
  width: 0px;
}

.sidebar-profile {
  height: 35px;
  width: 35px;
}

.edit_profile {
  width: 50%;
}

.edit_profile li {
  margin-bottom: 0px !important;
}

.small_heading {
  color: #56535b;
  font-family: "museo_slab700";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  opacity: 1;
}
.border_right {
  position: relative;
}

.border_right:after {
  position: absolute;
  content: "";
  top: 15px;
  height: 100%;
  right: 0;
  border-radius: 8px;
  border: 1px solid rgba(86, 83, 91, 0.1);
  background: #fff;
}
.name {
  color: #56535b !important;
  font-family: "museo_slab700" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  justify-content: start !important;
}

.profile_list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile_list li {
  margin-bottom: 30px;
}

.card_outer {
  border-radius: 10px;
  border: 1px solid rgb(86 83 91 / 0.2);
  background: #fff;
  padding: 15px;
}

.service_name {
  color: #584278;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  font-family: "museo_slab700";
}

.card_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_status {
  font-family: "museo_slab700";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 0;
}

.card_outer .name {
  padding: 10px 0;
}

.card_outer .small_heading {
  padding: 2px 0;
}

.sub_title {
  color: #56535b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: "Orelega One", cursive;
  text-transform: capitalize;
}

.complete {
  color: #0f993e;
}

.cancel {
  color: #ff4b26;
}

.border-bottom {
  margin-bottom: 40px;
}

.blank_btn {
  background-color: transparent;
}

.property_description {
  list-style-type: none;
}

.ql-editor {
  height: 200px;
  max-height: 200px;
}

.left_col button {
  background-color: #7c0279;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 5px;
}

.property-card {
  width: 100%;
}

.property_not_found {
  text-align: center;
  padding-top: 20px;
}

.property_not_found img {
  max-width: 250px;
}

.left_col img {
  height: 230px;
  width: 100%;
  object-fit: cover;
}

.table-btn {
  border: none;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  font-size: 14px;
}

.modal_icon svg {
  width: 30px;
  height: 30px;
}

.modal_msg {
  color: #56535b;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-family: "Orelega One";
  margin-top: 20px;
}

.modal_btns {
  margin-top: 30px;
}

.modal-body {
  padding-top: 25px;
  padding-bottom: 25px;
}

.modal_btns {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.modal_btns button {
  border-radius: 11px;
  background: transparent;
  text-align: center;
  font-size: 14px;
  color: #56535b;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  min-height: 36px;
  min-width: 112px;
  font-family: "Orelega One";
  line-height: 30px;
}

.modal_btns .purple {
  background: #7c0279;
  color: #fff;
}

.red {
  color: #ff4b26;
}

.green {
  color: #0f993e;
}

.create-admin a {
  padding: 10px 20px;
  border: 2px solid #7c0279;
  border-radius: 5px;
  background-color: transparent;
  color: #7c0279;
  text-decoration: none;
}

.form-check-input:checked {
  background-color: #7c0279;
  border-color: #7c0279;
}

.styled-checkbox:checked + label:after {
  background: #fff;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff,
    4px -6px 0 #fff, 4px -8px 0 #fff;
  content: "";
  height: 2px;
  left: 5px;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2px;
}

.broken-line {
  white-space: pre-wrap;
  line-height: 28px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.checkboxes label {
  align-items: center;
  display: flex;
}

.styled-checkbox + label:before {
  background: transparent;
  content: "";
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  vertical-align: text-top;
  width: 20px;
}

.styled-checkbox + label:before,
.styled-checkbox:focus + label:before {
  border: 1px solid rgba(86, 83, 91, 0.3);
  border-radius: 5px;
}

.styled-checkbox:checked + label:after {
  background: #fff;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff,
    4px -6px 0 #fff, 4px -8px 0 #fff;
  content: "";
  height: 2px;
  left: 5px;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2px;
}

.form-check-inline {
  position: relative;
}

.styled-checkbox:checked + label:before,
.styled-checkbox:focus + label:before {
  background: #7c0279;
}

/* loader is here */
.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #7c0279;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.stepper {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.step {
  text-align: center;
  margin-bottom: 10px;
}
.accommodation-text {
  font-size: 16px;
  font-weight: normal;
  padding: 10px;
  color: #56535b;
}
.accommodation-text .small_heading {
  font-weight: normal;
  font-size: 14px;
}
.accommodation-text .name {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.step.active {
  font-weight: bold;
  color: #000;
  text-align: left;
}
.imageSet {
  position: relative;
}
.imageSet .icon {
  position: absolute;
  top: 13px;
  left: 162px;
  background: white;
  padding: 7px;
  border-radius: 50px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.itineraryImages {
  width: 200px;
  height: 100px;
  border-radius: 10px;
  margin-top: 10px;
}

.uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-top: 15px;
  border: 2px dashed #ccc; /* Increased border width and adjusted color */
  border-radius: 10px;
  cursor: pointer; /* Add cursor pointer to indicate clickable/uploadable area */
}
.step-container {
  display: flex; /* Wrap step and line */
}
.mealChip {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.step-line {
  width: 2px; /* Adjust line width */
  border: 1px solid #ddd;
  height: auto; /* Adjust line height */
  margin: 0 10px 0 0; /* Adjust margins */
}

.step-line.active {
  background-color: #000;
}
.groupPic {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
th,
td {
  text-align: left;
}
.badge {
  display: flex;
  padding: 0.4em;
  font-size: 13px;
  font-weight: 100;
  width: fit-content;
  font-family: monospace;
  border-radius: 5px;
  color: white;
  gap: 6px;
  justify-content: center;
  line-height: normal;
  align-items: center;
  text-align: center;
  margin: 0.2em;
}
.badge.pending {
  background-color: #ff9800; /* Orange */
}
.errorMessage {
  color: red;
  font-size: 14px;
}
.badge.accepted {
  background-color: #4caf50; /* Green */
}
.badge.rejected {
  background-color: #f44336; /* Red */
}
.badge.admin {
  background-color: #8e9aaf; /* Red */
  color: #000;
}
.badge.user {
  background-color: #cbc0d3; /* Red */
  color: #000;
}
.badge.ongoing {
  background-color: #2196f3; /* Blue */
}
.badge.cancelled {
  background-color: #9e9e9e; /* Grey */
}

a {
  text-decoration: none !important;
}

.newBtn {
  align-items: center;
  appearance: none;
  border-radius: 4px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0,
    rgba(0, 0, 0, 0.12) 0 1px 5px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.0892857em;
  line-height: normal;
  min-width: 64px;
  outline: none;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  will-change: transform, opacity;
}

.newBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0,
    rgba(0, 0, 0, 0.12) 0 1px 10px 0;
}

.newBtn:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 0, rgba(0, 0, 0, 0.14) 0 0 0 0,
    rgba(0, 0, 0, 0.12) 0 0 0 0;
  color: rgba(0, 0, 0, 0.37);
  cursor: default;
  pointer-events: none;
}

.newBtn:not(:disabled) {
  background-color: #7c0279;
}

.newBtn:focus {
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0,
    rgba(0, 0, 0, 0.12) 0 1px 10px 0;
}

.newBtn:active {
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
  background: #a46bf5;
}

.nav.nav-items {
  background: #e9ecef;
  border-radius: 10px;
  padding: 10px;
}

.nav.nav-items .nav-link {
  color: #fff;
  background-color: #7c0279;
  padding: 10px;
  margin-left: 10px;
  /* height: 50px; */
  line-height: 9px;
  cursor: pointer;
  border-radius: 12px;
}

ul .airports {
  padding: 10px;
  list-style: none;
}

ul.airports li {
  list-style: none;
  cursor: pointer;
  margin-top: 6px;
}

.airport-List {
  height: 300px;
  overflow: auto;
  margin-top: 10px;
}

.airport-List::-webkit-scrollbar {
  width: 7px; /* width of the entire scrollbar */
}

.airport-List::-webkit-scrollbar-track {
  background: rgb(209, 207, 203); /* color of the tracking area */
}

.airport-List::-webkit-scrollbar-thumb {
  background-color: rgb(94, 29, 124); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(116, 111, 103); /* creates padding around scroll thumb */
}

.label-incomplete {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 12px;
  font-weight: 700;
  color: #5a6268;
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  border-radius: 0.25rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

/* Hide number input arrows */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* Hide number input arrows for Chrome, Edge, and Safari */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional - if you want to remove the space between the input and the spinner */
}

.view-groups {
  border: none;
  outline: none;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 2px 22px;
  border-radius: 10px;
  background-color: #ade8f4;
  color: #000;
  transition: background-color 0.3s ease;
}
/* Styling the button */
.view-groups {
  background-color: #ade8f4; /* Default background color */
  color: #000; /* Text color */
}

/* Hover effect */
.view-groups:hover {
  background-color: #caf0f8; /* Background color on hover */
}

ul.group-member-list {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
}

/* sendInvite css */
.dropdown-results div:hover {
  background-color: #f8f9fa;
}

.dropdown-results::-webkit-scrollbar {
  width: 7px;
}

.dropdown-results::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown-results::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}